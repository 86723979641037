<template>
  <v-container fluid style="height:100%;background-image: url('/imgs/mcv-background.jpg');background-size:cover;">
      <v-row justify="center" align="center" style="height:100%;">
          <!-- <home/> -->
          
      </v-row>
   </v-container>
</template>
<script>
// @ is an alias to /src
// import Home from '@/components/HomeView.vue'

export default {
  name: 'HomePage',
  components: {
    // Home
  },
  mounted() {
  },
}
</script>
